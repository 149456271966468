<template>
    <div>
        <ts-page-title
            :title="$t('serviceTicket.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('serviceTicket.pageTitle'),
                    href: '/admin/service-tickets'
                },
                {
                    text: $t('create'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <ts-loading-banner :loading="loading">
                    <form class="form-horizontal">
                        <div class="row">
                            <div class="col-md-12">
                                <Info
                                    v-model="model"
                                    :validate="errors"
                                    class="tw-p-3 tw-rounded"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <ts-loading-banner :loading="loading_detail">
                                    <Detail
                                        v-model="model"
                                        :validate="errors"
                                        class="tw-pt-6 tw-rounded"
                                    />
                                </ts-loading-banner>
                            </div>
                        </div>
                        <div class="row tw-pt-4">
                            <div
                                class="col-md-12 tw-space-x-4 tw-flex tw-justify-end"
                            >
                                <ts-button
                                    @click.prevent="
                                        $router.push({
                                            name: 'service-ticket'
                                        })
                                    "
                                    >{{ $t('cancel') }}</ts-button
                                >
                                <!-- <ts-button
                                    color="primary"
                                    outline
                                    :waiting="waiting"
                                    @click.prevent="saveAddNew"
                                    >{{ $t('saveAddNew') }}</ts-button
                                > -->
                                <ts-button
                                    color="primary"
                                    :waiting="waiting"
                                    @click.prevent="onSave"
                                    >{{ $t('save') }}</ts-button
                                >
                            </div>
                        </div>
                    </form>
                </ts-loading-banner>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import moment from 'moment'
import Info from './components/ticket-info.vue'
import Detail from './components/item-detail.vue'
import { mapActions, mapState } from 'vuex'

export default {
    name: 'quotationCreate',
    components: {
        Detail,
        Info
    },
    data () {
        return {
            errors: new Errors(),
            waiting_new: false,
            waiting: false,
            loading: false,
            loading_detail: false,
            src: null,
            model: {
                service_ticket_id: null,
                service_date: moment().format('DD-MM-YYYY HH:mm:ss'),
                customer_id: null,
                served_by: null,
                expected_completion_date: null,
                ticket_owner: null,
                ticket_status: 1,
                remarks: null,
                product_list: []
            }
        }
    },
    created () {
        this.loading = true
        this.fetchResources()
    },
    computed: {
        ...mapState('customerService/serviceTicket', ['formViewDatas'])
    },
    methods: {
        ...mapActions('customerService/serviceTicket', ['getFormViewModel']),
        async fetchResources () {
            this.loading = true
            await this.getFormViewModel({ params: {}, index: undefined })
            this.loading = false
        },
        saveAddNew () {
            this.waiting = true
            this.show_pdf = true
            this.errors = new Errors()
            this.$store
                .dispatch('customerService/serviceTicket/store', this.model)
                .then(response => {
                    this.src = response.url
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.clearInput()
                })
                .catch(error => {
                    this.$toasted.error(error.message)
                    this.errors = new Errors(error.errors)
                    this.show_pdf = false
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        onSave () {
            this.waiting = true
            this.errors = new Errors()
            this.$store
                .dispatch('customerService/serviceTicket/store', this.model)
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.$router.push({ name: 'service-ticket' })
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        clearInput () {
            this.model.service_ticket_id = null
            this.model.service_date = moment().format('DD-MM-YYYY HH:mm:ss')
            this.model.customer_id = null
            this.model.served_by = null
            this.model.expected_completion_date = null
            this.model.ticket_owner = null
            this.model.ticket_status = 1
            this.model.remarks = null
            this.model.product_list = []
            this.errors = new Errors()
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'SERVICE TICKET',
                desc: not.text
            })
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('customerService/serviceQuotation/RESET_STATE')
        next()
    }
}
</script>
